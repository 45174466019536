import React from 'react';
import PropTypes from 'prop-types';
import './cta-button.scss';
import classNames from 'classnames';

function CtaButton({ text, onClick, arrowDirection = 'down', className }) {

  const ctaButtonClasses = classNames(`cta-button ${className}`, {
    'cta-button--point-up': arrowDirection === 'up',
    'cta-button--point-down': arrowDirection === 'down',
    'cta-button--point-left': arrowDirection === 'left',
    'cta-button--point-right': arrowDirection === 'right',
  });

  return (
    <button type="button" className={ctaButtonClasses} onClick={onClick}>
      <div className="cta-button-left">{text}</div>
      <div className="cta-button-right">
        <div className="cta-button-right__inner">
          <svg width="100%" viewBox="0 0 8 8" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <g transform="translate(-104.000000, -8.000000)" fill="#010101" fillRule="nonzero">
                <polygon transform="translate(108.000000, 12.000000) rotate(-270.000000) translate(-108.000000, -12.000000) " points="104 12.4573805 104 11.5592516 110.316845 11.5592516 107.447465 8.64864865 108.072637 8 112 12.008316 108.072637 16 107.447465 15.3679834 110.316845 12.4573805"></polygon>
              </g>
            </g>
          </svg>
        </div>
      </div>
    </button>
  );
}

CtaButton.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func
}

export default CtaButton;