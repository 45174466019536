import React from "react";
import './404.scss';
import CtaButton from '../components/CtaButton';
import LocalizedLink from '../components/LocalizedLink';


function NotFoundPage() {

  return (
    <div className="page-404">
      <div className="page-404__content">
        <div className="page-404__error">404</div>
        <LocalizedLink to="/" className="block">
          <CtaButton text={'Home'} arrowDirection="right" />
        </LocalizedLink>
      </div>
    </div>
  );
}

export default NotFoundPage
